import { render, staticRenderFns } from "./dp3_t3_2.vue?vue&type=template&id=625770e9&scoped=true&"
import script from "./dp3_t3_2.vue?vue&type=script&lang=js&"
export * from "./dp3_t3_2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "625770e9",
  null
  
)

export default component.exports
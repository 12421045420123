import MyTextBox from './my_text_box';
import QuestionType01 from './question_type01';
import QuestionType02 from './question_type02';
import QuestionType11 from './question_type11';
import QuestionDesc from './question_desc';
const LogHeader = 'examination_parts/common_page';

export default {
  components: {
    MyTextBox,
    QuestionType01,
    QuestionType02,
    QuestionType11,
    QuestionDesc,
  },
  model: {
    prop: 'params',
    event: 'input',
  },
  props: {
    params: { default: null },
    examinationPart: { default: null },
    canEdit: { default: false },//my_text_boxの入力モード(isEditing=true)遷移可／不可を表すが、管理画面／受験画面のどちらから呼ばれたかの判定にも用いている
    showDescription: { default: false },
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  computed: {
    canAnswer() { return !(!this.canEdit && this.showDescription); },//学生側の解説表示でない？（＝チェックボックス選択可？）
    //↓全てのページをカバーするように（＝大は小を兼ねる）定義する
    internalS01: {
      get() {
        return this.params.s01;
      },
      set(newValue) {
        if (newValue != this.internalS01) {
          this.emitInput('s01', newValue);
        }
      }
    },
    internalS02: {
      get() {
        return this.params.s02;
      },
      set(newValue) {
        if (newValue != this.internalS02) {
          this.emitInput('s02', newValue);
        }
      }
    },
    internalS03: {
      get() {
        return this.params.s03;
      },
      set(newValue) {
        if (newValue != this.internalS03) {
          this.emitInput('s03', newValue);
        }
      }
    },
    //↓子コンポーネントで変更が発生したが為に呼ばれるのでset()内での変更チェックは不要
    internalQ001: { get() { return this.params.q001; }, set(newValue) { this.emitInput('q001', newValue); } },
    internalQ002: { get() { return this.params.q002; }, set(newValue) { this.emitInput('q002', newValue); } },
    internalQ003: { get() { return this.params.q003; }, set(newValue) { this.emitInput('q003', newValue); } },
    internalQ004: { get() { return this.params.q004; }, set(newValue) { this.emitInput('q004', newValue); } },
    internalQ005: { get() { return this.params.q005; }, set(newValue) { this.emitInput('q005', newValue); } },
    internalQ006: { get() { return this.params.q006; }, set(newValue) { this.emitInput('q006', newValue); } },
    internalQ007: { get() { return this.params.q007; }, set(newValue) { this.emitInput('q007', newValue); } },
    internalQ008: { get() { return this.params.q008; }, set(newValue) { this.emitInput('q008', newValue); } },
    internalQ009: { get() { return this.params.q009; }, set(newValue) { this.emitInput('q009', newValue); } },
    internalQ010: { get() { return this.params.q010; }, set(newValue) { this.emitInput('q010', newValue); } },
    internalQ011: { get() { return this.params.q011; }, set(newValue) { this.emitInput('q011', newValue); } },
    internalQ012: { get() { return this.params.q012; }, set(newValue) { this.emitInput('q012', newValue); } },
    internalQ013: { get() { return this.params.q013; }, set(newValue) { this.emitInput('q013', newValue); } },
    internalQ014: { get() { return this.params.q014; }, set(newValue) { this.emitInput('q014', newValue); } },
    internalQ015: { get() { return this.params.q015; }, set(newValue) { this.emitInput('q015', newValue); } },
    internalQ016: { get() { return this.params.q016; }, set(newValue) { this.emitInput('q016', newValue); } },
    internalQ017: { get() { return this.params.q017; }, set(newValue) { this.emitInput('q017', newValue); } },
    internalQ018: { get() { return this.params.q018; }, set(newValue) { this.emitInput('q018', newValue); } },
    internalQ019: { get() { return this.params.q019; }, set(newValue) { this.emitInput('q019', newValue); } },
    internalQ020: { get() { return this.params.q020; }, set(newValue) { this.emitInput('q020', newValue); } },
    internalQ021: { get() { return this.params.q021; }, set(newValue) { this.emitInput('q021', newValue); } },
    internalQ022: { get() { return this.params.q022; }, set(newValue) { this.emitInput('q022', newValue); } },
    internalQ023: { get() { return this.params.q023; }, set(newValue) { this.emitInput('q023', newValue); } },
    internalQ024: { get() { return this.params.q024; }, set(newValue) { this.emitInput('q024', newValue); } },
    internalQ025: { get() { return this.params.q025; }, set(newValue) { this.emitInput('q025', newValue); } },
    internalQ026: { get() { return this.params.q026; }, set(newValue) { this.emitInput('q026', newValue); } },
    internalQ027: { get() { return this.params.q027; }, set(newValue) { this.emitInput('q027', newValue); } },
    internalQ028: { get() { return this.params.q028; }, set(newValue) { this.emitInput('q028', newValue); } },
    internalQ029: { get() { return this.params.q029; }, set(newValue) { this.emitInput('q029', newValue); } },
    internalQ030: { get() { return this.params.q030; }, set(newValue) { this.emitInput('q030', newValue); } },
    internalQ031: { get() { return this.params.q031; }, set(newValue) { this.emitInput('q031', newValue); } },
    internalQ032: { get() { return this.params.q032; }, set(newValue) { this.emitInput('q032', newValue); } },
    internalQ033: { get() { return this.params.q033; }, set(newValue) { this.emitInput('q033', newValue); } },
    internalQ034: { get() { return this.params.q034; }, set(newValue) { this.emitInput('q034', newValue); } },
    internalQ035: { get() { return this.params.q035; }, set(newValue) { this.emitInput('q035', newValue); } },
    internalQ036: { get() { return this.params.q036; }, set(newValue) { this.emitInput('q036', newValue); } },
    internalQ037: { get() { return this.params.q037; }, set(newValue) { this.emitInput('q037', newValue); } },
    internalQ038: { get() { return this.params.q038; }, set(newValue) { this.emitInput('q038', newValue); } },
    internalQ039: { get() { return this.params.q039; }, set(newValue) { this.emitInput('q039', newValue); } },
    internalQ040: { get() { return this.params.q040; }, set(newValue) { this.emitInput('q040', newValue); } },
    internalQ041: { get() { return this.params.q041; }, set(newValue) { this.emitInput('q041', newValue); } },
    internalQ042: { get() { return this.params.q042; }, set(newValue) { this.emitInput('q042', newValue); } },
    internalQ043: { get() { return this.params.q043; }, set(newValue) { this.emitInput('q043', newValue); } },
    internalQ044: { get() { return this.params.q044; }, set(newValue) { this.emitInput('q044', newValue); } },
    internalQ045: { get() { return this.params.q045; }, set(newValue) { this.emitInput('q045', newValue); } },
    internalQ046: { get() { return this.params.q046; }, set(newValue) { this.emitInput('q046', newValue); } },
    internalQ047: { get() { return this.params.q047; }, set(newValue) { this.emitInput('q047', newValue); } },
    internalQ048: { get() { return this.params.q048; }, set(newValue) { this.emitInput('q048', newValue); } },
    internalQ049: { get() { return this.params.q049; }, set(newValue) { this.emitInput('q049', newValue); } },
    internalQ050: { get() { return this.params.q050; }, set(newValue) { this.emitInput('q050', newValue); } },
    internalQ051: { get() { return this.params.q051; }, set(newValue) { this.emitInput('q051', newValue); } },
    internalQ052: { get() { return this.params.q052; }, set(newValue) { this.emitInput('q052', newValue); } },
    internalQ053: { get() { return this.params.q053; }, set(newValue) { this.emitInput('q053', newValue); } },
    internalQ054: { get() { return this.params.q054; }, set(newValue) { this.emitInput('q054', newValue); } },
    internalQ055: { get() { return this.params.q055; }, set(newValue) { this.emitInput('q055', newValue); } },
    internalQ056: { get() { return this.params.q056; }, set(newValue) { this.emitInput('q056', newValue); } },
    internalQ057: { get() { return this.params.q057; }, set(newValue) { this.emitInput('q057', newValue); } },
    internalQ058: { get() { return this.params.q058; }, set(newValue) { this.emitInput('q058', newValue); } },
    internalQ059: { get() { return this.params.q059; }, set(newValue) { this.emitInput('q059', newValue); } },
    internalQ060: { get() { return this.params.q060; }, set(newValue) { this.emitInput('q060', newValue); } },
    internalQ061: { get() { return this.params.q061; }, set(newValue) { this.emitInput('q061', newValue); } },
    internalQ062: { get() { return this.params.q062; }, set(newValue) { this.emitInput('q062', newValue); } },
    internalQ063: { get() { return this.params.q063; }, set(newValue) { this.emitInput('q063', newValue); } },
    internalQ064: { get() { return this.params.q064; }, set(newValue) { this.emitInput('q064', newValue); } },
    internalQ065: { get() { return this.params.q065; }, set(newValue) { this.emitInput('q065', newValue); } },
    internalQ066: { get() { return this.params.q066; }, set(newValue) { this.emitInput('q066', newValue); } },
    internalQ067: { get() { return this.params.q067; }, set(newValue) { this.emitInput('q067', newValue); } },
    internalQ068: { get() { return this.params.q068; }, set(newValue) { this.emitInput('q068', newValue); } },
    internalQ069: { get() { return this.params.q069; }, set(newValue) { this.emitInput('q069', newValue); } },
    internalQ070: { get() { return this.params.q070; }, set(newValue) { this.emitInput('q070', newValue); } },
    internalQ071: { get() { return this.params.q071; }, set(newValue) { this.emitInput('q071', newValue); } },
    internalQ072: { get() { return this.params.q072; }, set(newValue) { this.emitInput('q072', newValue); } },
    internalQ073: { get() { return this.params.q073; }, set(newValue) { this.emitInput('q073', newValue); } },
    internalQ074: { get() { return this.params.q074; }, set(newValue) { this.emitInput('q074', newValue); } },
    internalQ075: { get() { return this.params.q075; }, set(newValue) { this.emitInput('q075', newValue); } },
    internalQ076: { get() { return this.params.q076; }, set(newValue) { this.emitInput('q076', newValue); } },
    internalQ077: { get() { return this.params.q077; }, set(newValue) { this.emitInput('q077', newValue); } },
    internalQ078: { get() { return this.params.q078; }, set(newValue) { this.emitInput('q078', newValue); } },
    internalQ079: { get() { return this.params.q079; }, set(newValue) { this.emitInput('q079', newValue); } },
    internalQ080: { get() { return this.params.q080; }, set(newValue) { this.emitInput('q080', newValue); } },
    internalQ081: { get() { return this.params.q081; }, set(newValue) { this.emitInput('q081', newValue); } },
    internalQ082: { get() { return this.params.q082; }, set(newValue) { this.emitInput('q082', newValue); } },
    internalQ083: { get() { return this.params.q083; }, set(newValue) { this.emitInput('q083', newValue); } },
    internalQ084: { get() { return this.params.q084; }, set(newValue) { this.emitInput('q084', newValue); } },
    internalQ085: { get() { return this.params.q085; }, set(newValue) { this.emitInput('q085', newValue); } },
    internalQ086: { get() { return this.params.q086; }, set(newValue) { this.emitInput('q086', newValue); } },
    internalQ087: { get() { return this.params.q087; }, set(newValue) { this.emitInput('q087', newValue); } },
    internalQ088: { get() { return this.params.q088; }, set(newValue) { this.emitInput('q088', newValue); } },
    internalQ089: { get() { return this.params.q089; }, set(newValue) { this.emitInput('q089', newValue); } },
    internalQ090: { get() { return this.params.q090; }, set(newValue) { this.emitInput('q090', newValue); } },
    internalQ091: { get() { return this.params.q091; }, set(newValue) { this.emitInput('q091', newValue); } },
    internalQ092: { get() { return this.params.q092; }, set(newValue) { this.emitInput('q092', newValue); } },
    internalQ093: { get() { return this.params.q093; }, set(newValue) { this.emitInput('q093', newValue); } },
    internalQ094: { get() { return this.params.q094; }, set(newValue) { this.emitInput('q094', newValue); } },
    internalQ095: { get() { return this.params.q095; }, set(newValue) { this.emitInput('q095', newValue); } },
    internalQ096: { get() { return this.params.q096; }, set(newValue) { this.emitInput('q096', newValue); } },
    internalQ097: { get() { return this.params.q097; }, set(newValue) { this.emitInput('q097', newValue); } },
    internalQ098: { get() { return this.params.q098; }, set(newValue) { this.emitInput('q098', newValue); } },
    internalQ099: { get() { return this.params.q099; }, set(newValue) { this.emitInput('q099', newValue); } },
    internalQ100: { get() { return this.params.q100; }, set(newValue) { this.emitInput('q100', newValue); } },
    internalQ101: { get() { return this.params.q101; }, set(newValue) { this.emitInput('q101', newValue); } },
    internalQ102: { get() { return this.params.q102; }, set(newValue) { this.emitInput('q102', newValue); } },
    internalQ103: { get() { return this.params.q103; }, set(newValue) { this.emitInput('q103', newValue); } },
    internalQ104: { get() { return this.params.q104; }, set(newValue) { this.emitInput('q104', newValue); } },
    internalQ105: { get() { return this.params.q105; }, set(newValue) { this.emitInput('q105', newValue); } },
    internalQ106: { get() { return this.params.q106; }, set(newValue) { this.emitInput('q106', newValue); } },
    internalQ107: { get() { return this.params.q107; }, set(newValue) { this.emitInput('q107', newValue); } },
    internalQ108: { get() { return this.params.q108; }, set(newValue) { this.emitInput('q108', newValue); } },
    internalQ109: { get() { return this.params.q109; }, set(newValue) { this.emitInput('q109', newValue); } },
    internalQ110: { get() { return this.params.q110; }, set(newValue) { this.emitInput('q110', newValue); } },
    internalQ111: { get() { return this.params.q111; }, set(newValue) { this.emitInput('q111', newValue); } },
    internalQ112: { get() { return this.params.q112; }, set(newValue) { this.emitInput('q112', newValue); } },
    internalQ113: { get() { return this.params.q113; }, set(newValue) { this.emitInput('q113', newValue); } },
    internalQ114: { get() { return this.params.q114; }, set(newValue) { this.emitInput('q114', newValue); } },
    internalQ115: { get() { return this.params.q115; }, set(newValue) { this.emitInput('q115', newValue); } },
    internalQ116: { get() { return this.params.q116; }, set(newValue) { this.emitInput('q116', newValue); } },
    internalQ117: { get() { return this.params.q117; }, set(newValue) { this.emitInput('q117', newValue); } },
    internalQ118: { get() { return this.params.q118; }, set(newValue) { this.emitInput('q118', newValue); } },
    internalQ119: { get() { return this.params.q119; }, set(newValue) { this.emitInput('q119', newValue); } },
    internalQ120: { get() { return this.params.q120; }, set(newValue) { this.emitInput('q120', newValue); } },
    internalQ121: { get() { return this.params.q121; }, set(newValue) { this.emitInput('q121', newValue); } },
    internalQ122: { get() { return this.params.q122; }, set(newValue) { this.emitInput('q122', newValue); } },
    internalQ123: { get() { return this.params.q123; }, set(newValue) { this.emitInput('q123', newValue); } },
    internalQ124: { get() { return this.params.q124; }, set(newValue) { this.emitInput('q124', newValue); } },
    internalQ125: { get() { return this.params.q125; }, set(newValue) { this.emitInput('q125', newValue); } },
    internalQ126: { get() { return this.params.q126; }, set(newValue) { this.emitInput('q126', newValue); } },
    internalQ127: { get() { return this.params.q127; }, set(newValue) { this.emitInput('q127', newValue); } },
    internalQ128: { get() { return this.params.q128; }, set(newValue) { this.emitInput('q128', newValue); } },
    internalQ129: { get() { return this.params.q129; }, set(newValue) { this.emitInput('q129', newValue); } },
    internalQ130: { get() { return this.params.q130; }, set(newValue) { this.emitInput('q130', newValue); } },
    internalQ131: { get() { return this.params.q131; }, set(newValue) { this.emitInput('q131', newValue); } },
    internalQ132: { get() { return this.params.q132; }, set(newValue) { this.emitInput('q132', newValue); } },
    internalQ133: { get() { return this.params.q133; }, set(newValue) { this.emitInput('q133', newValue); } },
    internalQ134: { get() { return this.params.q134; }, set(newValue) { this.emitInput('q134', newValue); } },
    internalQ135: { get() { return this.params.q135; }, set(newValue) { this.emitInput('q135', newValue); } },
    //↑全てのページをカバーするように（＝大は小を兼ねる）定義する
  },
  methods: {
    emitInput(key, newValue) {
      let newParams = {};
      //↓全てのページをカバーするように（＝大は小を兼ねる）定義する
      for (let n=1; n<=3; n++) {
        let noStr = ('0' + n).slice(-2);
        let orgKey = `s${noStr}`;//s01,s02,...
        let internalKey = `internalS${noStr}`;//internalS01,internalS02,...
        if (this.params[orgKey] !== undefined) {
          newParams[orgKey] = key==orgKey? newValue: this[internalKey]/*this.params[orgKey]*/;
        }
      }
      for (let n=1; n<=135; n++) {
        let noStr = ('00' + n).slice(-3);
        let orgKey = `q${noStr}`;//q001,q002,...
        let internalKey = `internalQ${noStr}`;//internalQ001,internalQ002,...
        if (this.params[orgKey] !== undefined) {
          newParams[orgKey] = key==orgKey? newValue: this[internalKey]/*this.params[orgKey]*/;
        }
      }
      //↑全てのページをカバーするように（＝大は小を兼ねる）定義する
      //console.log(`[${LogHeader}] emitting(input)`, newParams);//デバッグ用（本番コメントアウト）
      this.$emit('input', newParams);
    },
  },
}

<template>
  <div v-if="params">
    <div v-if="false/* デバッグ表示（本番false） */">common_t2_p04</div>

    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ046" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ047" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ048" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ049" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ050" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ051" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ052" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ053" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ054" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ055" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ056" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ057" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ058" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ059" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ060" />

  </div>
</template>

<script>
import commonMixin from './common_page';
const LogHeader = 'examination_parts/common_t2_p04';

export default {
  mixins: [
    commonMixin,
  ],
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>

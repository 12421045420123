<template>

  <!-- isEditing == false -->

  <div v-if="!isEditing&&!canUseFormula" class="my-text-box" :class="{ 'editable': canEdit }" v-html="internalValue" @click.prevent="startEditing()"></div>

  <div v-else-if="!isEditing&&canUseFormula" class="my-text-box" :class="{ 'editable': canEdit }" @click.prevent="startEditing()">
    <VueMathjax :formula="internalValue" :safe="false" :options="mathjaxOptions" />
  </div>

  <!-- isEditing == true -->

  <textarea v-else-if="isEditing&&isTextarea" class="my-text-box" ref="textarea" v-model="internalValue" @blur="finishEditing()"></textarea>
  <input v-else-if="isEditing&&!isTextarea" type="text" class="d-inline-block my-text-box" ref="input" v-model="internalValue" @keydown.enter="finishEditing()" @blur="finishEditing()" />

</template>

<script>
import { VueMathjax } from "vue-mathjax";

const LogHeader = 'examination_parts/my_text_box';
export default {
  components: {
    VueMathjax,
  },
  // model: {
  //   prop: 'value',
  //   event: 'input',
  // },
  props: {
    value: { default: null },
    canEdit: { default: false },
    isInline: { default: false },
    isTextarea: { default: false },
    canUseFormula: { default: false },
  },
  data() {
    // console.log(`[${LogHeader}] data() CALLED`);
    return {
      isEditing: false,
      mathjaxOptions: {
        displayAlign: 'left',
        showMathMenu: false,
        //↓template/index.htmlでのインポート時のconfig指定に依存するので注意
        tex2jax: {
          inlineMath: [ ['\\(','\\)'] ],
          displayMath: [ ['$$','$$'], ['\\[','\\]'] ],
        },
        'HTML-CSS': {
          fonts: ['Meiryo'/*, 'STIX', 'TeX'*/],
          undefinedFamily: "Meiryo, STIXGeneral, 'Arial Unicode MS'",
          mtextFontInherit: true,
          styles: {
            ".MathJax_Display": {
              margin: 0,//余計な上下マージンをクリア ※common.sassから移動した
            },
            '.text-bold .MathJax': {
              'font-weight': 'bold !important',
            },
            '.text-bold .MathJax .mn span, .MathJax .mo span': {// '.text-bold .MathJax' のboldが効かない対策
              'font-weight': 'bold !important',
            },
          },
        },
        // SVG: {
        //   font: 'Meiryo',//'STIX', 'TeX'
        //   undefinedFamily: "Meiryo, STIXGeneral, 'Arial Unicode MS'",
        // },
      },
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  mounted() {
    // console.log(`[${LogHeader}] mounted() CALLED`);
    if (this.canUseFormula) {
      MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (newValue != this.value) {
          if (newValue == '') {
            //ブランクで更新すると（表示エリアをクリックできなくなるので）二度と編集モードに入れなくなるので適当なダミー文字列を入れておく
            newValue = '&nbsp;';//半角SPは意味なし
          }
          this.$emit('input', newValue);
        }
      }
    }
  },
  methods: {
    startEditing() {
      if (!this.canEdit) {
        return;
      }
      this.isEditing = true;
      this.$nextTick(function () {
        if (this.isTextarea) {
          this.$refs.textarea.focus();
        } else {
          this.$refs.input.focus();
        }
      });
    },
    finishEditing() {
      this.isEditing = false;
    },
  },
};
</script>

<style lang="sass" scoped>
div.editable:hover
  cursor: pointer
input
  min-width: 70%
  max-width: 100%
  background-color: lightpink
  font-size: small
textarea
  display: block
  width: 100%
  height: 8em
  background-color: lightpink
</style>

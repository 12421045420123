import { render, staticRenderFns } from "./dp2_t3_1.vue?vue&type=template&id=dd031d66&scoped=true&"
import script from "./dp2_t3_1.vue?vue&type=script&lang=js&"
export * from "./dp2_t3_1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd031d66",
  null
  
)

export default component.exports
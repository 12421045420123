import { render, staticRenderFns } from "./common_t1_p05.vue?vue&type=template&id=8fcc4f68&scoped=true&"
import script from "./common_t1_p05.vue?vue&type=script&lang=js&"
export * from "./common_t1_p05.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fcc4f68",
  null
  
)

export default component.exports
<template>
  <div v-if="examinationPart&&currentPageComponent">
    <div v-if="false/* デバッグ表示（本番false） */">ページのコンポーネント（{{ currentPageComponent }}）</div>

    <keep-alive>
      <component class="examination_page" :is="currentPageComponent" :examinationPart="examinationPart" :canEdit="canEdit" :showDescription="showDescription" v-model="currentPageParams"></component>
    </keep-alive>

  </div>
</template>

<script>
import commonMixin from './common_exam_part';
import dp3_t3_2_p01 from './dp3_t3_2_p01';
import dp3_t3_2_p02 from './dp3_t3_2_p02';
import dp3_t3_2_p03 from './dp3_t3_2_p03';
import dp3_t3_2_p04 from './dp3_t3_2_p04';
import dp3_t3_2_p05 from './dp3_t3_2_p05';
import dp3_t3_2_p06 from './dp3_t3_2_p06';
const LogHeader = 'examination_parts/dp3_t3_2';

export default {
  mixins: [
    commonMixin,
  ],
  components: {
    dp3_t3_2_p01,
    dp3_t3_2_p02,
    dp3_t3_2_p03,
    dp3_t3_2_p04,
    dp3_t3_2_p05,
    dp3_t3_2_p06,
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  computed: {
    currentPageParams: {
      get() {
        return this.allPageParamsSet[this.currentPageName];
      },
      set(newValue) {//子コンポーネントで変更が発生したが為に呼ばれるので変更チェックは不要
        this.$emit('input', {
          p01: this.currentPageName=='p01'? newValue: this.params.p01,
          p02: this.currentPageName=='p02'? newValue: this.params.p02,
          p03: this.currentPageName=='p03'? newValue: this.params.p03,
          p04: this.currentPageName=='p04'? newValue: this.params.p04,
          p05: this.currentPageName=='p05'? newValue: this.params.p05,
          p06: this.currentPageName=='p06'? newValue: this.params.p06,
        });
      }
    },
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>

import { BIconShieldShaded } from 'bootstrap-vue';
import MyTextBox from './my_text_box';
const LogHeader = 'examination_parts/common_question';

export default {
  components: {
    MyTextBox,
  },
  model: {
    prop: 'params',
    event: 'input',
  },
  props: {
    params: { default: null },
    canEdit: { default: false },//my_text_boxの入力モード(isEditing=true)遷移可／不可を表すが、管理画面／受験画面のどちらから呼ばれたかの判定にも用いている
    canAnswer: { default: true },
    canUseFormula: { default: false },
    symbols: { default: () => [ 'A', 'B', 'C', 'D', 'E'] },
    isLowerCase: { default: false },
    isQuestionTextarea: { default: false },
  },
  data() {
    //console.log(`[${LogHeader}] data() CALLED`);
    return {
      has_correct_answer: (this.canEdit && this.params.answer)? true: false,//編集可（管理画面から）？ かつ 元々正解が設定されているか？ で判定 ※検査１，２のみがfalseとなることを想定
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  computed: {
    internalSymbols() { return this.isLowerCase? this.symbols.map((value,idx,a) => value.toLowerCase()): this.symbols; },
    //↓全てのQuestionTypeをカバーするように（＝大は小を兼ねる）定義する
    internalQuestion: {
      get() {
        return this.params.question;
      },
      set(newValue) {
        if (newValue != this.internalQuestion) {
          this.emitInput('question', newValue);
        }
      }
    },
    internalS01: {
      get() {
        return this.params.s01;
      },
      set(newValue) {
        if (newValue != this.internalS01) {
          this.emitInput('s01', newValue);
        }
      }
    },
    internalS02: {
        get() {
          return this.params.s02;
        },
        set(newValue) {
          if (newValue != this.internalS02) {
            this.emitInput('s02', newValue);
          }
        }
      },
    internalChoiceA: {
      get() {
        return this.params.choices[0];
      },
      set(newValue) {
        if (newValue != this.internalChoiceA) {
          this.emitInput('choice_A', newValue);
        }
      }
    },
    internalChoiceB: {
      get() {
        return this.params.choices[1];
      },
      set(newValue) {
        if (newValue != this.internalChoiceB) {
          this.emitInput('choice_B', newValue);
        }
      }
    },
    internalChoiceC: {
      get() {
        return this.params.choices[2];
      },
      set(newValue) {
        if (newValue != this.internalChoiceC) {
          this.emitInput('choice_C', newValue);
        }
      }
    },
    internalChoiceD: {
      get() {
        return this.params.choices[3];
      },
      set(newValue) {
        if (newValue != this.internalChoiceD) {
          this.emitInput('choice_D', newValue);
        }
      }
    },
    internalChoiceE: {
      get() {
        return this.params.choices[4];
      },
      set(newValue) {
        if (newValue != this.internalChoiceE) {
          this.emitInput('choice_E', newValue);
        }
      }
    },
    internalAnswer: {
      get() {
        return this.params.answer ?? [];
      },
      set(newValue) {
        //console.log(`[${LogHeader}] internalAnswer(newValue=[${newValue}]) CALLED`);
        if (newValue != this.internalAnswer) {
          if (Array.isArray(newValue)) {
            newValue.sort();//チェック順に格納されているのでソート
          }
          this.emitInput('answer', newValue);
        }
      }
    },
    internalDescription: {
      get() {
        return this.params.description ?? [];
      },
      set(newValue) {
        //console.log(`[${LogHeader}] internalDescription(newValue=[${newValue}]) CALLED`);
        if (newValue != this.internalDescription) {
          this.emitInput('description', newValue);
        }
      }
    },
    //210806 desc_answerはemitされることはない（＝受験者への解説表示時のみ使用）ので省略
    //↑全てのQuestionTypeをカバーするように（＝大は小を兼ねる）定義する
  },
  methods: {
    onChangeCheckbox(e) {
      if (this.canEdit) {//管理画面？
        if (this.has_correct_answer) {//正解あり？（ここで検査１、２を除外したい）
          if (!confirm('正解の選択肢を変更してもよろしいですか？\n（最終的に更新ボタンを押下しない限り確定されません。）')) {
              e.preventDefault();
          } else if (this.internalAnswer.length == 1 && !e.target.checked) {
            alert('正解の選択なしの異常事態を防ぐ為に、先に新しい選択肢をチェックしておいて下さい。');
            e.preventDefault();
          }
        }
      } else {//受験画面？
        if (!this.params.is_multi) {//複数選択不可？
          if (e.target.checked) {//【重要】選択を増やす場合のみチェック（＝選択を減らす場合はノーチェック）
            if (this.internalAnswer.length >= 1) {//２つ以上選択されている（しようとしている）？
              //↓【仕様変更】チェックを阻止せず、ラジオボタンのように振る舞うので廃止
              //alert('選択が多すぎます');
              //e.preventDefault();
              //↑
              this.internalAnswer = [ e.target.value ];//【仕様変更】チェックを阻止せず、ラジオボタンのように振る舞う
            }
          }
        }
      }
    },
    emitInput(key, newValue) {
      //↓全てのQuestionTypeをカバーするように（＝大は小を兼ねる）定義する
      let newParams = {//必ず存在するプロパティだけここでセット
        q_no: this.params.q_no,
        question: key=='question'? newValue: this.internalQuestion,
        //s01は後回し
        //s02は後回し
        choices: [
          key=='choice_A'? newValue: this.internalChoiceA,
          //choice_Bは後回し
          //choice_Cは後回し
          //choice_Dは後回し
          //choice_Eは後回し
        ],
        is_multi: this.canEdit? this.internalAnswer.length>1: this.params.is_multi,
        answer: key=='answer'? newValue: this.internalAnswer,
        description: key=='description'? newValue: this.internalDescription,
        //210806 desc_answerはemitされることはない（＝受験者への解説表示時のみ使用）ので省略
      };
      for (let n=1; n<=2; n++) {
        let noStr = ('0' + n).slice(-2);
        let orgKey = `s${noStr}`;//s01,s02,...
        let internalKey = `internalS${noStr}`;//internalS01,internalS02,...
        if (this.params[orgKey] !== undefined) {
          newParams[orgKey] = key==orgKey? newValue: this[internalKey]/*this.params[orgKey]*/;
        }
      }
      if (this.params.choices.length > 1) {
        newParams.choices[1] = key=='choice_B'? newValue: this.internalChoiceB;
        if (this.params.choices.length > 2) {
          newParams.choices[2] = key=='choice_C'? newValue: this.internalChoiceC;
          if (this.params.choices.length > 3) {
            newParams.choices[3] = key=='choice_D'? newValue: this.internalChoiceD;
            if (this.params.choices.length > 4) {
              newParams.choices[4] = key=='choice_E'? newValue: this.internalChoiceE;
            }
          }
        }
      }
      //↑全てのQuestionTypeをカバーするように（＝大は小を兼ねる）定義する
      //console.log(`[${LogHeader}] emitting(input)`, newParams);//デバッグ用（本番コメントアウト）
      this.$emit('input', newParams);
    },
  },
};

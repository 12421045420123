<template>
  <div v-if="params">
    <div v-if="false/* デバッグ表示（本番false） */">dp2_t3_2_p01</div>

    <div v-if="canAnswer" class="start_info mb-5">
　　  {{ examinationPart.exam_part_name }}は<span class="underline-black">全{{ examinationPart.page_count }}画面、{{ examinationPart.question_count }}問</span>で構成されています。<span class="text-red">制限時間は{{ examinationPart.time_limit_sec / 60 }}分</span>です。
    </div>

    <MyTextBox class="section_header my-3" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalS01" />

    <QuestionType11 :canEdit="canEdit" :canAnswer="canAnswer" :canUseFormula="canUseFormula" v-model="internalQ001" />

    <QuestionType11 :canEdit="canEdit" :canAnswer="canAnswer" :canUseFormula="canUseFormula" v-model="internalQ002" />

    <QuestionType11 :canEdit="canEdit" :canAnswer="canAnswer" :canUseFormula="canUseFormula" v-model="internalQ003" />

    <QuestionType11 :canEdit="canEdit" :canAnswer="canAnswer" :canUseFormula="canUseFormula" v-model="internalQ004" />

    <QuestionType11 :canEdit="canEdit" :canAnswer="canAnswer" :canUseFormula="canUseFormula" v-model="internalQ005" />

    <QuestionType11 :canEdit="canEdit" :canAnswer="canAnswer" :canUseFormula="canUseFormula" v-model="internalQ006" />

    <QuestionType11 :canEdit="canEdit" :canAnswer="canAnswer" :canUseFormula="canUseFormula" v-model="internalQ007" />

    <div v-if="showDescription" class="my-3">

      <hr />

      <div class="description_area_title my-2">【解説】</div>

      <QuestionDesc :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalQ001" />

      <QuestionDesc :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalQ002" />

      <QuestionDesc :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalQ003" />

      <QuestionDesc :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalQ004" />

      <QuestionDesc :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalQ005" />

      <QuestionDesc :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalQ006" />

      <QuestionDesc :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalQ007" />

    </div>

  </div>
</template>

<script>
import commonMixin from './common_page';
const LogHeader = 'examination_parts/dp2_t3_2_p01';

export default {
  mixins: [
    commonMixin,
  ],
  props: {
    canUseFormula: { default: true },
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>

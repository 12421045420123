<template>
  <div v-if="params" class="q_desc my-4">

    <div class="row">
      <div class="col-lg-12 description_caption">
        <span class="question_no">{{ params.q_no }}</span>
        <!-- 210806MOD
          受験後の解説時は、
            ・ここには正解を（従来通り）
            ・問題表示部のチェックボックスには受験者の回答を（従来は正解を表示していた）
          表示する必要があるので、両者を params.answer で共用していたのを answer と desc_answer に分離した。
          但し、従来通り両者とも正解を表示（かつ連動）が必要な場合（問題修正管理）もあるので、
          desc_answer がセットされていなければ従来通りの動きをするようにしてある。
        -->
        <span v-if="params.desc_answer" class="answer">正解{{ Array.isArray(params.desc_answer)? params.desc_answer.join('、'): '' }}</span>
        <span v-else class="answer">正解{{ Array.isArray(params.answer)? params.answer.join('、'): '' }}</span>
      </div>
    </div>

    <div class="row mt-2 mb-5">
      <div class="col-lg-12 description">
        <MyTextBox v-if="params.description" :isTextarea="true" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalDescription" />
      </div>
    </div>

  </div>
</template>

<script>
import commonMixin from './common_question';
//const LogHeader = 'examination_parts/q_desc';

export default {
  mixins: [
    commonMixin,
  ],
  props: {
    isTextarea: { default: true },//解説はデフォルトtrueにしておく
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>

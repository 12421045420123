<template>
  <div v-if="params">
    <div v-if="false/* デバッグ表示（本番false） */">common_t1_p01</div>

    <div v-if="canAnswer" class="start_info mb-4">
　　  {{ examinationPart.exam_part_name }}は<span class="underline-black">全{{ examinationPart.page_count }}画面、{{ examinationPart.question_count }}問</span>で構成されています。<span class="text-red">制限時間は{{ examinationPart.time_limit_sec / 60 }}分</span>です。
    </div>

    <MyTextBox class="start_info2 mb-4" :canEdit="canEdit" v-model="internalS01" />

    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ001" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ002" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ003" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ004" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ005" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ006" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ007" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ008" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ009" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ010" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ011" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ012" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ013" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ014" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ015" />

  </div>
</template>

<script>
import commonMixin from './common_page';
const LogHeader = 'examination_parts/common_t1_p01';

export default {
  mixins: [
    commonMixin,
  ],
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>

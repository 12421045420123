import { render, staticRenderFns } from "./common_t2_p07.vue?vue&type=template&id=317ff8e2&scoped=true&"
import script from "./common_t2_p07.vue?vue&type=script&lang=js&"
export * from "./common_t2_p07.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "317ff8e2",
  null
  
)

export default component.exports
<template>
  <div v-if="examinationPart&&currentPageComponent">
    <div v-if="false/* デバッグ表示（本番false） */">ページのコンポーネント（{{ currentPageComponent }}）</div>
    <keep-alive>
      <component class="examination_page" :is="currentPageComponent" :examinationPart="examinationPart" :canEdit="canEdit" :showDescription="showDescription" v-model="currentPageParams"></component>
    </keep-alive>

  </div>
</template>

<script>
import commonMixin from './common_exam_part';
import dp2_t3_1_p01 from './dp2_t3_1_p01';
import dp2_t3_1_p02 from './dp2_t3_1_p02';
import dp2_t3_1_p03 from './dp2_t3_1_p03';
import dp2_t3_1_p04 from './dp2_t3_1_p04';
const LogHeader = 'examination_parts/dp2_t3_1';

export default {
  mixins: [
    commonMixin,
  ],
  components: {
    dp2_t3_1_p01,
    dp2_t3_1_p02,
    dp2_t3_1_p03,
    dp2_t3_1_p04,
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  computed: {
    currentPageParams: {
      get() {
        return this.allPageParamsSet[this.currentPageName];
      },
      set(newValue) {//子コンポーネントで変更が発生したが為に呼ばれるので変更チェックは不要
        this.$emit('input', {
          p01: this.currentPageName=='p01'? newValue: this.params.p01,
          p02: this.currentPageName=='p02'? newValue: this.params.p02,
          p03: this.currentPageName=='p03'? newValue: this.params.p03,
          p04: this.currentPageName=='p04'? newValue: this.params.p04,
        });
      }
    },
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>

<template>
  <div class="report_wrapper">

    <!-- ヘッダ部 -->
    <div class="header_box" :class="{ offset_top_for_nav_bar: offsetTopForNavBar }">
      <div class="header_inner_box_1">
        <div class="header_inner_box_1_1">
          <div class="header_inner_box_1_1_1">
            <div class="caption_1">社会人基礎力テスト</div>
            <div class=""><span class="caption_2_1">{{ schoolExamStudent.school_examination.examination.exam_name }}</span><span class="caption_2_2">個人結果表</span></div>
          </div>
        </div><!-- header_inner_box_1 -->
        <div class="header_inner_box_1_2">
          <div class="header_inner_box_1_2_1">
            <div class="">{{ schoolExamStudent.school.school_name }}</div>
            <div class="">{{ schoolExamStudent.id_school_faculty? schoolExamStudent.school_faculty.faculty_name: '' }}</div>
            <div class="">{{ schoolExamStudent.id_school_department? schoolExamStudent.school_department.department_name: '' }}</div>
          </div>
          <div class="header_inner_box_1_2_2">{{ schoolExamStudent.student_no }}</div>
          <div class="header_inner_box_1_2_3">{{ schoolExamStudent.kana_full_name }}</div>
        </div><!-- header_inner_box_2 -->
      </div><!-- header_inner_box_1 -->
      <div v-if="false" class="header_inner_box_2">実施年月：{{ yearMonthOfTest }}</div>
    </div><!-- header_box -->
    <!-- ヘッダ部 -->

    <div class="body_box">

      <div v-if="true" class="alt_header_inner_box_2">実施年月：{{ yearMonthOfTest }}</div>

      <!-- パーソナリティ（検査１）部 -->
      <div class="section_box_wrapper">
        <div class="section_float_caption">パーソナリティ</div>
        <div class="section_box exam_part1">
          <div class="section_inner_box_wrapper type_1">
            <table class="section_inner_box">
              <thead><tr><td>要素</td><td>評価</td><td></td></tr></thead>
              <tbody>
                <tr v-for="aggregationKey in ExamPart1_AggregationKeys" :key="aggregationKey">
                  <td class="">{{ aggregationKey.substr(2) }}</td>
                  <td class="">{{ examPart1SectionData.table[aggregationKey].dev_score_rank }}</td>
                  <td class="">
                    <div class="dev_score_bar" :style="`width:${getDevBarLength(examPart1SectionData.table[aggregationKey].normalized_dev_score)}%;`"></div>
                  </td>
                </tr>
                <!-- グラフデバッグ用（本番コメントアウト） -->
                <!--
                <tr v-for="dev_score in [ 20, 30, 40, 50, 60, 70, 80 ]" :key="dev_score">
                  <td class="">テスト {{ dev_score }}</td>
                  <td class=""></td>
                  <td class="">
                    <div class="dev_score_bar" :style="`width:${getDevBarLength(dev_score)}%;`"></div>
                  </td>
                </tr>
                -->
              </tbody>
            </table><!-- section_inner_box -->
          </div><!-- section_inner_box_wrapper -->
          <div class="section_inner_box_wrapper type_2">
            <div class="section_inner_box">
              <div class="caption_box type_1">
                <div class="caption_1">パーソナリティ</div>
                <div class="caption_2">5つの要素は以下の特徴を示しています</div>
              </div>
              <ul class="desc_list">
                <li><div class="">開放性</div><div class="">知的好奇心の強さ、想像力の豊かさ、芸術的感受性の高さなど</div></li>
                <li><div class="">誠実性</div><div class="">自己規律、良心、勤勉性、計画性、責任感の強さなど</div></li>
                <li><div class="">外向性</div><div class="">社交性、積極性、活発さ、明るさなど</div></li>
                <li><div class="">協調性</div><div class="">他者への共感力、思いやり、献身さなど</div></li>
                <li><div class="">精神安定性</div><div class="">ストレス耐性、ネガティブな刺激に対する反応の強さ、不安やイライラが少ない、自尊心の高さなど</div></li>
              </ul>
            </div><!-- section_inner_box -->
          </div><!-- section_inner_box_wrapper -->
          <div class="section_inner_box_wrapper type_3">
            <div class="section_inner_box_outer_caption">あなたのパーソナリティ</div>
            <div class="section_inner_box">{{ examPart1SectionData.comment }}</div>
          </div><!-- section_inner_box_wrapper -->
        </div><!-- section_box-->
      </div><!-- section_box_wrapper -->
      <!-- パーソナリティ（検査１）部 -->

      <!-- 前に踏み出す力（検査２ CP1）部 -->
      <div class="section_box_wrapper">
        <div class="section_box exam_part2c1">
          <div class="section_inner_box_wrapper type_1">
            <div class="section_caption"><span class="">キャリアポイント</span><span class="">１</span><span class="">前に踏み出す力</span></div>
            <table class="section_inner_box">
              <thead><tr><td>要素</td><td>評価</td><td></td></tr></thead>
              <tbody>
                <tr v-for="(aggregationKey, idx) in ExamPart2C1_AggregationKeys" :key="aggregationKey">
                  <td class="">{{ aggregationKey.substr(3) }}</td>
                  <td class="">{{ examPart2C1SectionData.table[aggregationKey].dev_score_rank }}</td>
                  <td class="">
                    <div class="dev_score_bar" :class="{ is_total_bar: idx == (ExamPart2C1_AggregationKeys.length - 1) }" :style="`width:${getDevBarLength(examPart2C1SectionData.table[aggregationKey].normalized_dev_score)}%;`"></div>
                  </td>
                </tr>
              </tbody>
            </table><!-- section_inner_box -->
          </div><!-- section_inner_box_wrapper -->
          <div class="section_inner_box_wrapper type_2">
            <div class="section_inner_box">
              <div class="caption_box type_2">
                <img class="caption_img" src="../assets/exam_part2c1.png" alt="画像" />
                <div>
                  <div class="caption_1">前に踏み出す力</div>
                  <div class="caption_2">一歩前に踏み出し、失敗しても粘り強く取り組む力</div>
                </div>
              </div>
              <ul class="desc_list">
                <li><div class="">主体性</div><div class="">物事に進んで取り組む力</div></li>
                <li><div class="">働きかけ力</div><div class="">他人に働きかけ巻き込む力</div></li>
                <li><div class="">実行力</div><div class="">目的を設定し確実に行動する力</div></li>
              </ul>
            </div><!-- section_inner_box -->
          </div><!-- section_inner_box_wrapper -->
          <div class="section_inner_box_wrapper type_3">
            <div class="section_inner_box_outer_caption">あなたの「前に踏み出す⼒」</div>
            <div class="section_inner_box">{{ examPart2C1SectionData.comment }}</div>
          </div><!-- section_inner_box_wrapper -->
        </div><!-- section_box-->
      </div><!-- section_box_wrapper -->
      <!-- 前に踏み出す力（検査２ CP1）部 -->

      <!-- 考え抜く力（検査２ CP2）部 -->
      <div class="section_box_wrapper">
        <div class="section_box exam_part2c2">
          <div class="section_inner_box_wrapper type_1">
            <div class="section_caption"><span class="">キャリアポイント</span><span class="">２</span><span class="">考え抜く力</span></div>
            <table class="section_inner_box">
              <thead><tr><td>要素</td><td>評価</td><td></td></tr></thead>
              <tbody>
                <tr v-for="(aggregationKey, idx) in ExamPart2C2_AggregationKeys" :key="aggregationKey">
                  <td class="">{{ aggregationKey.substr(3) }}</td>
                  <td class="">{{ examPart2C2SectionData.table[aggregationKey].dev_score_rank }}</td>
                  <td class="">
                    <div class="dev_score_bar" :class="{ is_total_bar: idx == (ExamPart2C2_AggregationKeys.length - 1) }" :style="`width:${getDevBarLength(examPart2C2SectionData.table[aggregationKey].normalized_dev_score)}%;`"></div>
                  </td>
                </tr>
              </tbody>
            </table><!-- section_inner_box -->
          </div><!-- section_inner_box_wrapper -->
          <div class="section_inner_box_wrapper type_2">
            <div class="section_inner_box">
              <div class="caption_box type_2">
                <img class="caption_img" src="../assets/exam_part2c2.png" alt="画像" />
                <div>
                  <div class="caption_1">考え抜く力</div>
                  <div class="caption_2">疑問を持ち、考え抜く力</div>
                </div>
              </div>
              <ul class="desc_list">
                <li><div class="">課題発見力</div><div class="">現状を分析し目的や課題を明らかにする力</div></li>
                <li><div class="">計画力</div><div class="">課題の解決に向けたプロセスを明らかにし準備する力</div></li>
                <li><div class="">創造力</div><div class="">新しい価値を生み出す力</div></li>
              </ul>
            </div><!-- section_inner_box -->
          </div><!-- section_inner_box_wrapper -->
          <div class="section_inner_box_wrapper type_3">
            <div class="section_inner_box_outer_caption">あなたの「考え抜く⼒」</div>
            <div class="section_inner_box">{{ examPart2C2SectionData.comment }}</div>
          </div><!-- section_inner_box_wrapper -->
        </div><!-- section_box-->
      </div><!-- section_box_wrapper -->
      <!-- 考え抜く力（検査２ CP2）部 -->

      <!-- チームで働く力（検査２ CP3）部 -->
      <div class="section_box_wrapper">
        <div class="section_box exam_part2c3">
          <div class="section_inner_box_wrapper type_1">
            <div class="section_caption"><span class="">キャリアポイント</span><span class="">３</span><span class="">チームで働く力</span></div>
            <table class="section_inner_box">
              <thead><tr><td>要素</td><td>評価</td><td></td></tr></thead>
              <tbody>
                <tr v-for="(aggregationKey, idx) in ExamPart2C3_AggregationKeys" :key="aggregationKey">
                  <td v-if="aggregationKey=='C3-ストレスコントロール力'">ストレス<br/>コントロール力</td><!-- 特例 -->
                  <td v-else class="">{{ aggregationKey.substr(3) }}</td>
                  <td class="">{{ examPart2C3SectionData.table[aggregationKey].dev_score_rank }}</td>
                  <td class="">
                    <div class="dev_score_bar" :class="{ is_total_bar: idx == (ExamPart2C3_AggregationKeys.length - 1) }" :style="`width:${getDevBarLength(examPart2C3SectionData.table[aggregationKey].normalized_dev_score)}%;`"></div>
                  </td>
                </tr>
              </tbody>
            </table><!-- section_inner_box -->
          </div><!-- section_inner_box_wrapper -->
          <div class="section_inner_box_wrapper type_2">
            <div class="section_inner_box">
              <div class="caption_box type_2">
                <img class="caption_img" src="../assets/exam_part2c3.png" alt="画像" />
                <div>
                  <div class="caption_1">チームで働く力</div>
                  <div class="caption_2">多様な人々とともに、目標に向けて協力する力</div>
                </div>
              </div>
              <ul class="desc_list">
                <li><div class="">発信力</div><div class="">自分の意見をわかりやすく伝える力</div></li>
                <li><div class="">傾聴力</div><div class="">相手の意見を丁寧に聴く力</div></li>
                <li><div class="">柔軟性</div><div class="">意見の違いや立場の違いを理解する力</div></li>
                <li><div class="">情況把握力</div><div class="">自分と周囲の人々や物事との関係性を理解する力</div></li>
                <li><div class="">規律性</div><div class="">社会のルールや人との約束を守る力</div></li>
                <li><div class="">ストレスコントロール力</div><div class="">ストレスの発生源に対応する力</div></li>
              </ul>
            </div><!-- section_inner_box -->
          </div><!-- section_inner_box_wrapper -->
          <div class="section_inner_box_wrapper type_3">
            <div class="section_inner_box_outer_caption">あなたの「チームで働く⼒」</div>
            <div class="section_inner_box">{{ examPart2C3SectionData.comment }}</div>
          </div><!-- section_inner_box_wrapper -->
        </div><!-- section_box-->
      </div><!-- section_box_wrapper -->
      <!-- チームで働く力（検査２ CP3）部 -->

      <!-- 基礎能力（検査３）部 -->
      <div v-if="hasExamPart3" class="section_box_wrapper">
        <div class="section_float_caption">基礎能力</div>
        <div class="section_box exam_part3">
          <div class="section_inner_box_wrapper type_4">
            <div class="section_inner_box_outer_caption">あなたの成績</div>
            <table class="section_inner_box">
              <thead><tr><td></td><td>点数</td><td>偏差値</td><td>全国順位</td><td></td></tr></thead>
              <tbody>
                <tr v-for="(caption, idx) in [ '言語', '計数', '総合' ]" :key="caption">
                  <td class="">{{ caption }}</td>
                  <td class="">{{ examPart3SectionData.table_1[idx].score }}</td>
                  <td class="">{{ examPart3SectionData.table_1[idx].rounded_dev_score }}</td>
                  <td class="">{{ examPart3SectionData.table_1[idx].national_rank }}/10000</td>
                  <td class="">
                    <div class="dev_score_bar" :class="{ is_total_bar: caption == '総合' }" :style="`width:${getDevBarLength(examPart3SectionData.table_1[idx].normalized_dev_score)}%;`"></div>
                  </td>
                </tr>
                <!-- グラフデバッグ用（本番コメントアウト） -->
                <!--
                <tr v-for="dev_score in [ 20, 30, 40, 50, 60, 70, 80 ]" :key="dev_score">
                  <td class="">テスト {{ dev_score }}</td>
                  <td class=""></td>
                  <td class="">{{ dev_score }}</td>
                  <td class="">1/10000</td>
                  <td class="">
                    <div class="dev_score_bar" :style="`width:${getDevBarLength(dev_score)}%;`"></div>
                  </td>
                </tr>
                -->
              </tbody>
            </table><!-- section_inner_box -->
            <div class="remark">・全国順位：全国の就職希望学生を1万人とした場合のあなたの相対的な順位を示しています。</div>
          </div><!-- section_inner_box_wrapper -->
          <div class="section_inner_box_wrapper type_5">
            <div class="section_inner_box_outer_caption">正誤表</div>
            <div class="section_inner_box">
              <div class="section_inner_box_inner_caption">言語</div>
              <table class="question31_1to15">
                <thead>
                  <tr>
                    <td class="">問題No.</td>
                    <td v-for="n in 15" :key="`tb2r1_${n}`" class="">{{ n }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">正誤判定</td>
                    <td v-for="n in 15" :key="`tb2r2_${n}`" class="">{{ examPart3SectionData.table_2[n-1].result_mark }}</td>
                  </tr>
                  <tr>
                    <td class="">正答率</td>
                    <td v-for="n in 15" :key="`tb2r3_${n}`" class="">{{ examPart3SectionData.table_2[n-1].correct_rate_pct }}</td>
                  </tr>
                </tbody>
              </table>
            </div><!-- section_inner_box -->
            <div class="section_inner_box">
              <table class="question31_1to10">
                <thead>
                  <tr>
                    <td class="">問題No.</td>
                    <td v-for="n in 10" :key="`tb2r1_${n}`" class="">{{ n }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">正誤判定</td>
                    <td v-for="n in 10" :key="`tb2r2_${n}`" class="">{{ examPart3SectionData.table_2[n-1].result_mark }}</td>
                  </tr>
                  <tr>
                    <td class="">正答率</td>
                    <td v-for="n in 10" :key="`tb2r3_${n}`" class="">{{ examPart3SectionData.table_2[n-1].correct_rate_pct }}</td>
                  </tr>
                </tbody>
              </table>
            </div><!-- section_inner_box -->
            <div class="section_inner_box">
              <table class="question31_1to5">
                <thead>
                  <tr>
                    <td class="">問題No.</td>
                    <td v-for="n in 5" :key="`tb2r1_${n}`" class="">{{ n }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">正誤判定</td>
                    <td v-for="n in 5" :key="`tb2r2_${n}`" class="">{{ examPart3SectionData.table_2[n-1].result_mark }}</td>
                  </tr>
                  <tr>
                    <td class="">正答率</td>
                    <td v-for="n in 5" :key="`tb2r3_${n}`" class="">{{ examPart3SectionData.table_2[n-1].correct_rate_pct }}</td>
                  </tr>
                </tbody>
              </table>
            </div><!-- section_inner_box -->
            <div class="section_inner_box">
              <table class="question31_6to10">
                <thead>
                  <tr>
                    <td class="">問題No.</td>
                    <td v-for="n in 5" :key="`tb2r1_${5+n}`" class="">{{ 5 + n }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">正誤判定</td>
                    <td v-for="n in 5" :key="`tb2r2_${5+n}`" class="">{{ examPart3SectionData.table_2[(5+n)-1].result_mark }}</td>
                  </tr>
                  <tr>
                    <td class="">正答率</td>
                    <td v-for="n in 5" :key="`tb2r3_${5+n}`" class="">{{ examPart3SectionData.table_2[(5+n)-1].correct_rate_pct }}</td>
                  </tr>
                </tbody>
              </table>
            </div><!-- section_inner_box -->
            <div class="section_inner_box">
              <table class="question31_11to15">
                <thead>
                  <tr>
                    <td class="">問題No.</td>
                    <td v-for="n in 5" :key="`tb2r1_${10+n}`" class="">{{ 10 + n }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">正誤判定</td>
                    <td v-for="n in 5" :key="`tb2r2_${10+n}`" class="">{{ examPart3SectionData.table_2[(10+n)-1].result_mark }}</td>
                  </tr>
                  <tr>
                    <td class="">正答率</td>
                    <td v-for="n in 5" :key="`tb2r3_${10+n}`" class="">{{ examPart3SectionData.table_2[(10+n)-1].correct_rate_pct }}</td>
                  </tr>
                </tbody>
              </table>
            </div><!-- section_inner_box -->
            <div class="section_inner_box">
              <div class="section_inner_box_inner_caption">計数</div>
              <table class="question32_1to15">
                <thead>
                  <tr>
                    <td class="">問題No.</td>
                    <td v-for="n in 15" :key="`tb3r1_${n}`" class="">{{ n }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">正誤判定</td>
                    <td v-for="n in 15" :key="`tb3r2_${n}`" class="">{{ examPart3SectionData.table_3[n-1].result_mark }}</td>
                  </tr>
                  <tr>
                    <td class="">正答率</td>
                    <td v-for="n in 15" :key="`tb3r3_${n}`" class="">{{ examPart3SectionData.table_3[n-1].correct_rate_pct }}</td>
                  </tr>
                </tbody>
              </table>
            </div><!-- section_inner_box -->
            <div class="section_inner_box">
              <table class="question32_1to10">
                <thead>
                  <tr>
                    <td class="">問題No.</td>
                    <td v-for="n in 10" :key="`tb3r1_${n}`" class="">{{ n }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">正誤判定</td>
                    <td v-for="n in 10" :key="`tb3r2_${n}`" class="">{{ examPart3SectionData.table_3[n-1].result_mark }}</td>
                  </tr>
                  <tr>
                    <td class="">正答率</td>
                    <td v-for="n in 10" :key="`tb3r3_${n}`" class="">{{ examPart3SectionData.table_3[n-1].correct_rate_pct }}</td>
                  </tr>
                </tbody>
              </table>
            </div><!-- section_inner_box -->
            <div class="section_inner_box">
              <table class="question32_11to20">
                <thead>
                  <tr>
                    <td class="">問題No.</td>
                    <td v-for="n in 10" :key="`tb3r1_${10+n}`" class="">{{ 10 + n }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">正誤判定</td>
                    <td v-for="n in 10" :key="`tb3r2_${10+n}`" class="">{{ examPart3SectionData.table_3[(10+n)-1].result_mark }}</td>
                  </tr>
                  <tr>
                    <td class="">正答率</td>
                    <td v-for="n in 10" :key="`tb3r3_${10+n}`" class="">{{ examPart3SectionData.table_3[(10+n)-1].correct_rate_pct }}</td>
                  </tr>
                </tbody>
              </table>
            </div><!-- section_inner_box -->
            <div class="section_inner_box">
              <table class="question32_1to5">
                <thead>
                  <tr>
                    <td class="">問題No.</td>
                    <td v-for="n in 5" :key="`tb3r1_${n}`" class="">{{ n }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">正誤判定</td>
                    <td v-for="n in 5" :key="`tb3r2_${n}`" class="">{{ examPart3SectionData.table_3[n-1].result_mark }}</td>
                  </tr>
                  <tr>
                    <td class="">正答率</td>
                    <td v-for="n in 5" :key="`tb3r3_${n}`" class="">{{ examPart3SectionData.table_3[n-1].correct_rate_pct }}</td>
                  </tr>
                </tbody>
              </table>
            </div><!-- section_inner_box -->
            <div class="section_inner_box">
              <table class="question32_6to10">
                <thead>
                  <tr>
                    <td class="">問題No.</td>
                    <td v-for="n in 5" :key="`tb3r1_${5+n}`" class="">{{ 5 + n }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">正誤判定</td>
                    <td v-for="n in 5" :key="`tb3r2_${5+n}`" class="">{{ examPart3SectionData.table_3[(5+n)-1].result_mark }}</td>
                  </tr>
                  <tr>
                    <td class="">正答率</td>
                    <td v-for="n in 5" :key="`tb3r3_${5+n}`" class="">{{ examPart3SectionData.table_3[(5+n)-1].correct_rate_pct }}</td>
                  </tr>
                </tbody>
              </table>
            </div><!-- section_inner_box -->
            <div class="section_inner_box">
              <table class="question32_11to15">
                <thead>
                  <tr>
                    <td class="">問題No.</td>
                    <td v-for="n in 5" :key="`tb3r1_${10+n}`" class="">{{ 10 + n }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">正誤判定</td>
                    <td v-for="n in 5" :key="`tb3r2_${10+n}`" class="">{{ examPart3SectionData.table_3[(10+n)-1].result_mark }}</td>
                  </tr>
                  <tr>
                    <td class="">正答率</td>
                    <td v-for="n in 5" :key="`tb3r3_${10+n}`" class="">{{ examPart3SectionData.table_3[(10+n)-1].correct_rate_pct }}</td>
                  </tr>
                </tbody>
              </table>
            </div><!-- section_inner_box -->
            <div class="section_inner_box">
              <table class="question32_16to20">
                <thead>
                  <tr>
                    <td class="">問題No.</td>
                    <td v-for="n in 5" :key="`tb3r1_${15+n}`" class="">{{ 15 + n }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">正誤判定</td>
                    <td v-for="n in 5" :key="`tb3r2_${15+n}`" class="">{{ examPart3SectionData.table_3[(15+n)-1].result_mark }}</td>
                  </tr>
                  <tr>
                    <td class="">正答率</td>
                    <td v-for="n in 5" :key="`tb3r3_${15+n}`" class="">{{ examPart3SectionData.table_3[(15+n)-1].correct_rate_pct }}</td>
                  </tr>
                </tbody>
              </table>
            </div><!-- section_inner_box -->
            <div class="section_inner_box">
              <ul class="remark_list"><!-- FIXME デザイン上はこのブロックはNo.16-20のテーブルの右にあるが、flexでもなかなか難しい（No.1-15のテーブルと罫線を揃えたままレスポンシブにする方策が思いつかない） -->
                <li>・正誤判定の〇は正解、×は不正解、Nは無回答を表しています。</li>
                <li>・正答率は、全国を基準としています。</li>
                <li>・正答率は「正解数÷回答数」にて計算しています（無回答は除外）。</li>
              </ul>
            </div><!-- section_inner_box -->
          </div><!-- section_inner_box_wrapper -->
        </div><!-- section_box-->
      </div><!-- section_box_wrapper -->
      <!-- 基礎能力（検査３）部 -->

    </div><!-- body_box -->

  </div>
</template>

<script>
const LogHeader = 'PersonalReportComponent';
const ExamPart1_AggregationKeys = [//サーバー側App/Models/ExamPartStatsSample.phpとの整合注意
  'P-開放性',
  'P-誠実性',
  'P-外向性',
  'P-協調性',
  'P-精神安定性',
];
const ExamPart2C1_AggregationKeys = [//サーバー側App/Models/ExamPartStatsSample.phpとの整合注意
  'C1-主体性',
  'C1-働きかけ力',
  'C1-実行力',
  'C1-総合評価',//サーバーー側と違ってキー配列に直接入れてある
];
const ExamPart2C2_AggregationKeys = [//サーバー側App/Models/ExamPartStatsSample.phpとの整合注意
  'C2-課題発見力',
  'C2-計画力',
  'C2-創造力',
  'C2-総合評価',//サーバーー側と違ってキー配列に直接入れてある
];
const ExamPart2C3_AggregationKeys = [//サーバー側App/Models/ExamPartStatsSample.phpとの整合注意
  'C3-発信力',
  'C3-傾聴力',
  'C3-柔軟性',
  'C3-情況把握力',
  'C3-規律性',
  'C3-ストレスコントロール力',
  'C3-総合評価',//サーバーー側と違ってキー配列に直接入れてある
];

export default {
  props: {
    schoolExamStudent: { default: null },
    offsetTopForNavBar: { default: false },
  },
  data() {
    // console.log(`[${LogHeader}] data() CALLED`);
    return {
      ExamPart1_AggregationKeys,
      ExamPart2C1_AggregationKeys,
      ExamPart2C2_AggregationKeys,
      ExamPart2C3_AggregationKeys,
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  computed: {
    yearMonthOfTest() {
      let dt = new Date(this.schoolExamStudent.finished_at);
      let year = dt.getFullYear();
      let month = ('00' + (dt.getMonth()+1)).slice(-2);
      return `${year}年${month}月`;
    },
    examPart1SectionData() { return this.schoolExamStudent.personal_report_set['exam_part_1']; },
    examPart2C1SectionData() { return this.schoolExamStudent.personal_report_set['exam_part_2_c1']; },
    examPart2C2SectionData() { return this.schoolExamStudent.personal_report_set['exam_part_2_c2']; },
    examPart2C3SectionData() { return this.schoolExamStudent.personal_report_set['exam_part_2_c3']; },
    hasExamPart3() { return this.schoolExamStudent.personal_report_set['has_exam_part_3']; },
    examPart3SectionData() { return this.hasExamPart3? this.schoolExamStudent.personal_report_set['exam_part_3']: null; },
  },
  methods: {
    getDevBarLength(normalizedDevScore) {
      // const score20Pos = 10;//% ※完全に等分にする場合は25
      // const unitLength = (100 - score20Pos) / (80 - 20);
      const barLength = 10/*score20Pos*/ + (normalizedDevScore - 20.0) * 1.5/*unitLength*/;//20の位置が確定したらハードコーディングした方がよい
      return barLength;
    }
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/_base"

.report_wrapper

  $body_bg_color: rgb(215, 237, 225)

  .header_box
    //position: sticky //【仕様変更】ヘッダ部も普通にスクロールさせる
    top: 0
    &.offset_top_for_nav_bar
      top: 56px //管理画面のナビバーの高さ
    left: 0
    z-index: 1000

    .header_inner_box_1
      display: flex
      flex-wrap: wrap
      $mq_arg_1: min_768
      $header_inner_box_1_1_width_rate: 55%
      $header_inner_box_1_2_width_rate: 100% - $header_inner_box_1_1_width_rate

      .header_inner_box_1_1 //ヘッダー左半分
        width: 100%
        +mq($mq_arg_1)
          flex: 0 0 $header_inner_box_1_1_width_rate
          max-width: $header_inner_box_1_1_width_rate
        padding: 1.0em 0
        +mq($mq_arg_1)
          padding: 0 //flexに任せる
        $header_inner_box_1_1_bg_color: rgb(34, 183, 134)
        background-color: $header_inner_box_1_1_bg_color
        display: flex
        justify-content: center
        align-items: center
        .header_inner_box_1_1_1
          font-size: 1.5rem
          +mq($mq_arg_1)
            font-size: 2.0rem
          .caption_1 //社会人基礎力テスト
            color: #FFFFFF
          .caption_2_1 //DP-1,2,3
            padding: 0 0.3em
            color: $header_inner_box_1_1_bg_color
            background-color: #FFFFFF
          .caption_2_2 //個人結果表
            margin-left: 0.3em
            color: #FFFFFF
      //.header_inner_box_1_1

      .header_inner_box_1_2 //ヘッダー右半分
        width: 100%
        +mq($mq_arg_1)
          flex: 0 0 $header_inner_box_1_2_width_rate
          max-width: $header_inner_box_1_2_width_rate
        padding: 0.5em 1.0em
        +mq($mq_arg_1)
          padding: 1.0em 2.0em
        background-color: rgb(198, 230, 213)
        display: flex
        flex-wrap: wrap
        $between_margin: 5px
        .header_inner_box_1_2_1 //学校、所属１、所属２
          margin-bottom: $between_margin
          width: 100%
          +mq(min_992)
            flex: 0 0 100%
            max-width: 100%
          padding-left: 1em
          background-color: #FFFFFF
        .header_inner_box_1_2_2 //学籍番号
          margin-top: $between_margin
          width: 100%
          +mq(min_992)
            flex: 0 0 calc(30% - #{$between_margin})
            max-width: calc(30% - #{$between_margin})
            margin-right: $between_margin
          display: inline-block
          padding-left: 1em
          background-color: #FFFFFF
        .header_inner_box_1_2_3 //カナ氏名
          margin-top: $between_margin
          width: 100%
          +mq(min_992)
            flex: 0 0 calc(70% - #{$between_margin})
            max-width: calc(70% - #{$between_margin})
            margin-left: $between_margin
          display: inline-block
          background-color: #FFFFFF
          text-align: center
      //.header_inner_box_1_2

    .header_inner_box_2 //実施年月 ※ヘッダーに含める場合
      width: 100%
      background-color: $body_bg_color
      padding-right: 1em
      text-align: right
  //.header_box

  $section_box_border_color: rgb(40, 185, 137)
  $section_box_border_set: solid 3px $section_box_border_color
  $section_inner_box_type_1_border_set: solid 1.5px $section_box_border_color
  $section_inner_box_type_2_bg_color: rgb(255, 250, 194)
  $section_inner_box_type_3_border_set: solid 0.5px $section_box_border_color

  .body_box
    background-color: $body_bg_color
    padding: 0.5em 0.5em 1em
    +mq(min_576)
      padding: 0.5em 1em 2em //1em 2em 2em //alt_header_inner_box_2を使う場合はtopは少なめでよい
    +mq(min_992)
      padding: 0.5em 2em 2em //1em 2em 2em //alt_header_inner_box_2を使う場合はtopは少なめでよい

    .alt_header_inner_box_2 //実施年月 ※本体に含める場合
      width: 100%
      //padding-right: 1em
      text-align: right
      font-size: 0.8em
      +mq(min_576)
        font-size: inherit

    .section_box_wrapper
      position: relative
      &:not(:last-of-type)
        margin-bottom: 2em
      &:nth-of-type(4) //キャリアポイント３と基礎能力の間
        margin-bottom: 3em

      .section_float_caption //パーソナリティ、基礎能力
        position: absolute
        top: -1.0em
        left: -0.2em
        +mq(min_576)
          left: -0.5em
        & + .section_box.exam_part1 .section_inner_box_wrapper.type_1
          padding-top: 2.5em
        & + .section_box.exam_part3
          padding-top: 2.5em
        border: $section_box_border_set
        border-radius: 0.5em
        color: $section_box_border_color
        background-color: #FFFFFF
        display: inline-block
        padding: 0.25em 0.5em
        +mq(min_576)
          padding: 0.25em 1.5em
        text-align: center
        font-size: 1.0rem
        +mq(min_576)
          font-size: 1.5rem
        font-weight: bold

      .section_box
        border: $section_box_border_set
        border-radius: 0.5em
        background-color: #FFFFFF
        padding: 0.5em 0.5em 0.5em //padding-topについてsection_float_captionも参照
        +mq(min_576)
          padding: 0.5em 1em 1em
        display: flex
        flex-wrap: wrap

        .section_inner_box_wrapper.type_1 //要素・評価・偏差値グラフ
          width: 100%
          +mq(min_992)
            flex: 0 0 50%
            max-width: 50%
          margin-bottom: 1em
          .section_caption //キャリアポイントのみでパーソナリティには存在しない
            margin-bottom: 0.5em
            font-size: 1.2em
            span:nth-of-type(1) //キャリアポイント
              color: $section_box_border_color
              font-weight: bold
            span:nth-of-type(2) //数字
              margin-left: 0.2em
              margin-right: 0.5em
              color: #FFFFFF
              background-color: $section_box_border_color
              padding: 0 0.2em
              font-weight: bold
            span:nth-of-type(3) //前に踏み出す力、考え抜く力、チームで働く力
              font-weight: bold
              display: block
              +mq(min_451)
                display: inline
          //.section_caption
          table.section_inner_box
            border: $section_inner_box_type_1_border_set
            //↓角丸テーブル用
            border-collapse: separate
            border-spacing: 0
            border-radius: 0.5em
            thead tr td:not(:last-of-type),
            tr:not(:last-of-type) td:not(:last-of-type)
              border-bottom: $section_inner_box_type_1_border_set
            td:not(:last-of-type)
              border-right: $section_inner_box_type_1_border_set
            //↑角丸テーブル用
            thead
              color: $section_box_border_color
              td:nth-of-type(1) //要素
                width: 8em
              td:nth-of-type(2) //評価
                width: 4em
              td:nth-of-type(3) //グラフ
                width: 18em
            td
              &:not(:last-of-type)
                text-align: center
          //.section_inner_box
        //.section_inner_box_wrapper.type_1

        .section_inner_box_wrapper.type_2 //要素の特徴
          width: 100%
          +mq(min_992)
            flex: 0 0 50%
            max-width: 50%
          margin-bottom: 1em
          .section_inner_box
            border-radius: 0.5em
            background-color: $section_inner_box_type_2_bg_color
            padding: 0.5em 0.2em
            +mq(min_768)
              padding: 0.5em 0.5em
            .caption_box
              padding: 0.5em 0.5em
              +mq(min_768)
                padding: 0.5em 0.5em
              &.type_2
                background-color: #FFFFFF
                display: flex
              .caption_img //CP1～3のピクトグラム
                display: none
                +mq(min_768)
                  display: block
                  width: 4em
                  height: 4em
                  margin: 0.5em
                  margin-right: 1em
              .caption_1 //パーソナリティ、前に踏み出す力、考え抜く力、チームで働く力
                border-radius: 1.0em
                display: inline-block
                padding: 0.25em 1.5em
                color: #FFFFFF
                background-color: $section_box_border_color
                text-align: center
                font-weight: bold
              .caption_2 //５つの要素は～、一歩前に踏み出し～、
                margin-top: 0.5em
                font-weight: bold
            //.caption_box
            ul.desc_list
              margin-top: 0.5em
              margin-bottom: 0
              //list-style: none
              list-style-position: inside
              padding-left: 0.5em
              font-size: 0.8rem
              li
                &::marker
                  color: $section_box_border_color
                margin-bottom: 0.5em
                div
                  &:nth-of-type(1)
                    display: inline-block
                    min-width: 8em
                    &::after
                      content: " ・・・"
                  &:nth-of-type(2)
                    display: block
                    margin-left: 1.4em
                    +mq(min_576)
                      display: inline-block
            //.desc_list
          //.section_inner_box
        //.section_inner_box_wrapper.type_2

        .section_inner_box_wrapper.type_3 //コメント
          width: 100%
          +mq(min_992)
            flex: 0 0 100%
            max-width: 100%
          .section_inner_box_outer_caption
            &::before
              content: "■"
              padding-right: 0.3em
          .section_inner_box
            border: $section_inner_box_type_3_border_set
            border-radius: 0.5em
            padding: 0.5em 1.0em
        //.section_inner_box_wrapper.type_3

        .section_inner_box_wrapper.type_4 .section_inner_box_outer_caption, //あなたの成績
        .section_inner_box_wrapper.type_5 .section_inner_box_outer_caption //正誤表
          border-radius: 0.5em
          width: 10em
          margin-bottom: 0.2em
          display: inline-block
          color: #FFFFFF
          background-color: $section_box_border_color
          text-align: center
          font-size: 1.2em

        .section_inner_box_wrapper.type_4 //要素・点数・偏差値・全国順位・偏差値グラフ ※実質的に「基礎能力」専用
          //section_inner_box_outer_caption 前掲
          width: 100%
          margin-bottom: 1em
          table.section_inner_box
            border: $section_inner_box_type_1_border_set
            //↓角丸テーブル用
            border-collapse: separate
            border-spacing: 0
            border-radius: 0.5em
            thead tr td:not(:last-of-type),
            tr:not(:last-of-type) td:not(:last-of-type)
              border-bottom: $section_inner_box_type_1_border_set
            td:not(:last-of-type)
              border-right: $section_inner_box_type_1_border_set
            //↑角丸テーブル用
            thead
              color: $section_box_border_color
              td:nth-of-type(1) //要素
                width: 7.5em
              td:nth-of-type(2) //点数
                width: 4.5em
              td:nth-of-type(3) //偏差値
                width: 5.0em
              td:nth-of-type(4) //全国順位
                width: 8.0em
              td:nth-of-type(5) //グラフ
                width: 24.0em
            td
              &:not(:last-of-type)
                text-align: center
          //.section_inner_box
          .remark //・全国順位：全国の～
            color: gray
            font-size: 0.8rem
        //.section_inner_box_wrapper.type_4

        .section_inner_box_wrapper.type_5 //正誤表 ※実質的に「基礎能力」専用 ※実質的に「基礎能力」専用
          //section_inner_box_outer_caption 前掲
          width: 100%
          .section_inner_box
            .section_inner_box_inner_caption //言語、計数
              &::before
                content: "■"
                padding-right: 0.3em
            table
              border: $section_inner_box_type_1_border_set
              //↓角丸テーブル用
              border-collapse: separate
              border-spacing: 0
              border-radius: 0.5em
              thead tr td,
              tr:not(:last-of-type) td
                border-bottom: $section_inner_box_type_1_border_set
              td:not(:last-of-type)
                border-right: $section_inner_box_type_1_border_set
              //↑角丸テーブル用
              margin-bottom: 0.5em
              thead
                color: $section_box_border_color
              td
                text-align: center

              $caption_cell_width_1: 7.5vw
              $question_cell_width_1: 3.5vw
              $caption_cell_width_2: 6.0vw
              $question_cell_width_2: 3.3vw

              +mq(max_575) //別にmqで括らなくてもよいが他のmqと見比べやすくする為に括った
                $caption_cell_width: 20.0vw
                $question_cell_width: 10.0vw
                &[class^="question31_"],
                &[class^="question32_"]
                  display: none
                &.question31_1to5,
                &.question31_6to10,
                &.question31_11to15,
                &.question32_1to5,
                &.question32_6to10,
                &.question32_11to15,
                &.question32_16to20
                  display: block
                  font-size: normal
                  width: $caption_cell_width + $question_cell_width * 5
                thead
                  td:first-of-type //問題No.
                    width: $caption_cell_width
                  td:not(:first-of-type) //１～
                    width: $question_cell_width

              +mq(min_576)
                $caption_cell_width: 12.5vw
                $question_cell_width: 6.0vw
                &[class^="question31_"],
                &[class^="question32_"]
                  display: none
                &.question31_1to10,
                &.question31_11to15,
                &.question32_1to10,
                &.question32_11to20
                  display: block
                  font-size: small
                &.question31_1to10,
                &.question32_1to10,
                &.question32_11to20
                  width: $caption_cell_width + $question_cell_width * 10
                &.question31_11to15
                  width: $caption_cell_width + $question_cell_width * 5
                thead
                  td:first-of-type //問題No.
                    width: $caption_cell_width
                  td:not(:first-of-type) //１～
                    width: $question_cell_width

              +mq(min_768)
                $caption_cell_width: 10.0vw
                $question_cell_width: 4.2vw
                &[class^="question31_"],
                &[class^="question32_"]
                  display: none
                &.question31_1to15,
                &.question32_1to15,
                &.question32_16to20
                  display: block
                  font-size: normal
                &.question31_1to15,
                &.question32_1to15
                  width: $caption_cell_width + $question_cell_width * 15
                &.question32_16to20
                  width: $caption_cell_width + $question_cell_width * 5
                thead
                  td:first-of-type //問題No.
                    width: $caption_cell_width
                  td:not(:first-of-type) //１～
                    width: $question_cell_width

              +mq(min_992)
                $caption_cell_width: 8.0em
                $question_cell_width: 2.7em
                &.question31_1to15,
                &.question32_1to15
                  width: $caption_cell_width + $question_cell_width * 15
                &.question32_16to20
                  width: $caption_cell_width + $question_cell_width * 5
                thead
                  td:first-of-type //問題No.
                    width: $caption_cell_width
                  td:not(:first-of-type) //１～
                    width: $question_cell_width

            //table
            ul.remark_list
              padding-left: 0.5em
              list-style: none
              color: gray
              font-size: 0.8rem
          //.section_inner_box
        //.section_inner_box_wrapper.type_5

        .dev_score_bar //偏差値棒グラフのバー ※PDF版との色の整合注意 see app/Examinations/AbstractExaminationService.php
          height: 1em
          line-height: 1em
          $normal_bar_color: rgb(153, 200, 236)
          color: $normal_bar_color
          background-color: $normal_bar_color
          &.is_total_bar
            $total_bar_color: rgb(20, 160, 219)
            color: $total_bar_color
            background-color: $total_bar_color

      //.section_box
    //.section_box_wrapper
  //.body_box

//.report_wrapper

</style>

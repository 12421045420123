<template>
  <div v-if="params" class="q_type02">

    <div class="row my-2">
      <div class="col-lg-12 question">
        <div class="question_no">{{ params.q_no }}</div>
        <MyTextBox class="question_str" :isTextarea="isQuestionTextarea" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalQuestion" />
      </div>
    </div>

    <div class="row my-3">
      <div v-if="params.s01!==undefined&&params.s02!==undefined" class="col-lg-12 question_str2">
        <MyTextBox :isTextarea="true" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalS01" />
        <MyTextBox class="text-right" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalS02" />
      </div>
    </div>

    <div class="row my-2">
      <ul class="col-lg-12 choices">
        <li v-for="(c_no, idx) in params.choices.length" :key="`choice_${idx}`">
          <input type="checkbox" class="choice_checkbox" :id="`q${params.q_no}_${c_no}`" :value="internalSymbols[idx]" :disabled="!canAnswer" @click="onChangeCheckbox" v-model="internalAnswer">
          <label :for="`q${params.q_no}_${c_no}`" class="choice_symbol">{{ internalSymbols[idx] }}</label>
          <MyTextBox v-if="idx==0" class="choice_desc" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalChoiceA" />
          <MyTextBox v-if="idx==1" class="choice_desc" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalChoiceB" />
          <MyTextBox v-if="idx==2" class="choice_desc" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalChoiceC" />
          <MyTextBox v-if="idx==3" class="choice_desc" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalChoiceD" />
          <MyTextBox v-if="idx==4" class="choice_desc" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalChoiceE" />
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
import commonMixin from './common_question';
//const LogHeader = 'examination_parts/q_type02';

//type01とは・・・
//問題文と選択肢の間のs01(textarea)、s02(input、右寄せ)のみ異なる

export default {
  mixins: [
    commonMixin,
  ],
  props: {
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>

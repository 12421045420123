<template>
  <div v-if="params">
    <div v-if="false/* デバッグ表示（本番false） */">common_t2_p06</div>

    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ076" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ077" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ078" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ079" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ080" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ081" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ082" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ083" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ084" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ085" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ086" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ087" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ088" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ089" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ090" />

  </div>
</template>

<script>
import commonMixin from './common_page';
const LogHeader = 'examination_parts/common_t2_p06';

export default {
  mixins: [
    commonMixin,
  ],
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>

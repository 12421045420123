<template>
  <div v-if="params">
    <div v-if="false/* デバッグ表示（本番false） */">common_t2_p05</div>

    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ061" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ062" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ063" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ064" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ065" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ066" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ067" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ068" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ069" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ070" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ071" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ072" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ073" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ074" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ075" />

  </div>
</template>

<script>
import commonMixin from './common_page';
const LogHeader = 'examination_parts/common_t2_p05';

export default {
  mixins: [
    commonMixin,
  ],
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>

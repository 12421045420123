<template>
  <div v-if="params" class="q_type11">

    <div class="row my-2">
      <div class="col-lg-12 question">
        <div class="question_no">{{ params.q_no }}</div>
        <MyTextBox class="question_str" :isTextarea="isQuestionTextarea" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalQuestion" />
      </div>
    </div>

    <div class="row my-3">

      <!-- isLeftRightReversed? 選択肢が左: 選択肢が右  -->

      <div :class="descColClass" class="question_str2">
        <MyTextBox v-if="params.s01!==undefined" :class="{ 'mb-3': params.s01!=='&nbsp;' }" :isTextarea="isS01Textarea" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalS01" />
      </div>

      <ul :class="choicesColClass" class="choices">
        <li v-for="(c_no, idx) in params.choices.length" :key="`choice_${idx}`">
          <input type="checkbox" class="choice_checkbox" :id="`q${params.q_no}_${c_no}`" :value="internalSymbols[idx]" :disabled="!canAnswer" @click="onChangeCheckbox" v-model="internalAnswer">
          <label :for="`q${params.q_no}_${c_no}`" class="choice_symbol">{{ internalSymbols[idx] }}</label>
          <MyTextBox v-if="idx==0" class="choice_desc" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalChoiceA" />
          <MyTextBox v-if="idx==1" class="choice_desc" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalChoiceB" />
          <MyTextBox v-if="idx==2" class="choice_desc" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalChoiceC" />
          <MyTextBox v-if="idx==3" class="choice_desc" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalChoiceD" />
          <MyTextBox v-if="idx==4" class="choice_desc" :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalChoiceE" />
        </li>
      </ul>

    </div><!-- row -->

  </div>
</template>

<script>
import commonMixin from './common_question';
//const LogHeader = 'examination_parts/q_type11';

export default {
  mixins: [
    commonMixin,
  ],
  props: {
    widthRates: { default: () => [0, 5, 0, 5] },
    isLeftRightReversed: { default: false },
    isS01Textarea: { default: false },
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  computed: {
    descColClass() { return this.isLeftRightReversed? `col-lg-${this.widthRates[3]} offset-lg-${this.widthRates[2]} order-1`: `col-lg-${this.widthRates[1]} offset-lg-${this.widthRates[0]} order-0`; },
    choicesColClass() { return this.isLeftRightReversed? `col-lg-${this.widthRates[1]} offset-lg-${this.widthRates[0]} order-0`: `col-lg-${this.widthRates[3]} offset-lg-${this.widthRates[2]} order-1`; },
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>

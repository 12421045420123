<template>
  <div v-if="params">
    <div v-if="false/* デバッグ表示（本番false） */">common_t2_p07</div>

    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ091" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ092" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ093" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ094" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ095" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ096" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ097" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ098" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ099" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ100" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ101" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ102" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ103" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ104" />
    <QuestionType01 :canEdit="canEdit" :choice_count="4" :isLowerCase="true" v-model="internalQ105" />

  </div>
</template>

<script>
import commonMixin from './common_page';
const LogHeader = 'examination_parts/common_t2_p07';

export default {
  mixins: [
    commonMixin,
  ],
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>

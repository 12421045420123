<template>
  <div v-if="params">
    <div v-if="false/* デバッグ表示（本番false） */">dp2_t3_2_p03</div>

    <QuestionType01 :canEdit="canEdit" :canAnswer="canAnswer" :canUseFormula="canUseFormula" :isS01Textarea="true" v-model="internalQ011" />

    <QuestionType01 :canEdit="canEdit" :canAnswer="canAnswer" :canUseFormula="canUseFormula" :isQuestionTextarea="true" v-model="internalQ012" />

    <QuestionType01 :canEdit="canEdit" :canAnswer="canAnswer" :canUseFormula="canUseFormula" :isQuestionTextarea="true" v-model="internalQ013" />

    <div v-if="showDescription" class="my-3">

      <hr />

      <div class="description_area_title my-2">【解説】</div>

      <QuestionDesc :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalQ011" />

      <QuestionDesc :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalQ012" />

      <QuestionDesc :canEdit="canEdit" :canUseFormula="canUseFormula" v-model="internalQ013" />

    </div>

  </div>
</template>

<script>
import commonMixin from './common_page';
const LogHeader = 'examination_parts/dp2_t3_2_p03';

export default {
  mixins: [
    commonMixin,
  ],
  props: {
    canUseFormula: { default: true },
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
  },
  deactivated() {
    console.log(`[${LogHeader}] deactivated() CALLED`);
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
</style>
